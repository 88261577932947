<template>
<v-app>
    <!-- Header -->
    <v-app-bar app color="#514cf1" :elevation="0" dark>
        <v-container fluid class="px-0 px-md-4">
            <v-row align="center" no-gutters>
                <v-col cols="auto" class="d-flex align-center">
                    <img :src="require('../assets/logo.png')" alt="Cloud Host" class="img-fluid">
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex align-center">
                    <v-btn text class="links px-0 px-md-2" href="#services">Servicios</v-btn>
                    <v-btn text class="links px-2 px-md-2" href="#about">Nosotros</v-btn>
                    <v-btn text class="links px-0 px-md-2"  @click="dialog = true">Contacto</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>

    <!-- Hero Section -->
    <v-main class="pt-0">
        <v-row align="center" justify="center" class="hero-background py-16 m-0">
            <v-container class="pa-5">
                <v-row>
                    <v-col cols="12" md="6" class="text-left">
                        <p style="color: #00ccff;">VELOCIDAD TECNOLOGÍA INNOVACIÓN</p>
                        <h1 class="display-1 font-weight-bold white--text" style="font-size: 2.7rem !important;">SOMOS LA MEJOR<br> OPCIÓN PARA TUS IDEAS</h1>
                        <p class="subtitle-1 mt-6 white--text">Nuestra oferta es la más completa del mercado para el almacenamiento de documentos, correos y sitios web. Nos especializamos en garantizar el servicio constante, eficaz y veloz de nuestros servidores.</p>
                        <v-btn color="black" large class="my-4" dark rounded @click="dialog = true">¡EMPEZAR YA!</v-btn>
                    </v-col>

                    <v-col cols="12" md="6" class="d-flex align-center justify-center">
                        <img src="../assets/imagen10.png" alt="Cloud Server" class="img-hero">
                    </v-col>
                </v-row>
            </v-container>
        </v-row>

        <!-- Features Section -->
        <v-container class="pa-5 mt-10" id="about">
            <v-row align="center" justify="center" class="text-center mt-5">
                <v-col cols="12" md="2">
                    <img :src="require('../assets/ICONO1.png')" class="img-icons">
                    <p class="caption">Servicio técnico certificado</p>
                </v-col>
                <v-col cols="12" md="2">
                    <img :src="require('../assets/ICONO2.png')" class="img-icons">
                    <p class="caption">Nivel de encriptación AES256</p>
                </v-col>
                <v-col cols="12" md="2">
                    <img :src="require('../assets/ICONO3.png')" class="img-icons">
                    <p class="caption">Discos de gran performance</p>
                </v-col>
                <v-col cols="12" md="2">
                    <img :src="require('../assets/ICONO4.png')" class="img-icons">
                    <p class="caption text-bold">Micro latencias para BD/Servidores</p>
                </v-col>
                <v-col cols="12" md="2">
                    <img :src="require('../assets/ICONO5.png')" class="img-icons">
                    <p class="caption">Somos partners Cpanel</p>
                </v-col>
            </v-row>
        </v-container>

        <v-row align="center" justify="center" class="text-center mt-5 mb-10">
            <hr class="custom-hr">
        </v-row>

        <!-- Services Included Section -->
        <v-row>
            <v-container>
                <h2 class="text-center mb-13 custom-title text-uppercase">Tu servidor además tiene incluido</h2>
                <v-row class="justify-center" no-gutters>

                    <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pa-2">
                        <v-card class="text-center card-fixed-size" max-width="300">
                            <div class="image-container">
                                <img :src="require('../assets/ICONO6.png')" class="img-icons">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <p class="badget mx-5">Soporte Unificado</p>
                                Un equipo atento a ayudarte
                            </v-card-subtitle>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pa-2">
                        <v-card class="text-center card-fixed-size" max-width="300">
                            <div class="image-container">
                                <img :src="require('../assets/ICONO7.png')" style="height: 93px;" class="img-icons">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <p class="badget mx-5">Backup cloud vps colombia</p>
                                Copias de Respaldo Automáticas Gratis
                            </v-card-subtitle>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pa-2">
                        <v-card class="text-center card-fixed-size" max-width="300">
                            <div class="image-container">
                                <img :src="require('../assets/ICONO8.png')" style="width: 78px; height: 93px;" class="img-icons">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <p class="badget mx-5">Imagen servidor</p>
                                Acronis y Uptime Robot
                            </v-card-subtitle>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pa-2">
                        <v-card class="text-center card-fixed-size" max-width="300">
                            <div class="image-container">
                                <img src="../assets/ICONO9.png">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <p class="badget mx-5">Espacio ssd ha</p>
                                Almacenamiento SSD de alto performance
                            </v-card-subtitle>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pa-2">
                        <v-card class="text-center card-fixed-size" max-width="300">
                            <div class="image-container">
                                <img :src="require('../assets/ICONO10.png')" class="img-icons">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <p class="badget mx-5">Red privada disponible</p>
                                Puedes conectar en el futuro otros servidores solo visibles en tu red interna
                            </v-card-subtitle>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3" class="d-flex flex-column align-center text-center pa-2">
                        <v-card class="text-center card-fixed-size" max-width="300">
                            <div class="image-container">
                                <img :src="require('../assets/ICONO11.png')" class="img-icons">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <p class="badget mx-5">Tu negocios sin interrupciones</p>
                                Tu servidor cuenta con un equipo de ingenieros experimentados, con ellos tu sitio esta siempre disponible y con el mejor desempeño
                            </v-card-subtitle>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-row>

        <!-- Cloud Services Section -->
        <v-row align="center" justify="center" class="cloud-background py-2 m-0" id="services">
            <v-container>
                <h2 class="text-center mb-6 white--text">SERVICIOS CLOUD</h2>
                <v-row>
                    <v-col cols="12" md="6" class="d-flex justify-center">
                        <img src="../assets/imagen2.png" alt="Cloud Server" class="img-service">
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12" sm="6" class="d-flex align-center mb-4">
                                <div class="text-right">
                                    <p class="badget-cloud ml-10">TOTAL ADMINISTRACIÓN</p>
                                    <p class="white--text">Con la capacidad de enviar y administrar documentos,
                                        así como fusionarse con varios contactos a la vez, es la herramienta perfecta para cualquier negocio.</p>
                                </div>
                                <v-avatar class="mr-3" rounded="0" size="48">
                                    <v-img src="../assets/ICONO12.png"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" sm="6" class="d-flex align-center mb-4">
                                <div class="text-right">
                                    <p class="badget-cloud ml-10">UN EQUIPO A TU SERVICIO</p>
                                    <p class="white--text">Te ayudamos vía Whatsapp, Messenger, Email, Chat y desde tu móvil.</p>
                                </div>
                                <v-avatar class="mr-3" rounded="0" size="48">
                                    <v-img src="../assets/ICONO14.png"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" sm="6" class="d-flex align-center mb-4">
                                <div class="text-right">
                                    <p class="badget-cloud ml-10">SINCRONIZACIÓN</p>
                                    <p class="white--text">Con nuestra ayuda podrás acceder a tus documentos en cualquier momento,
                                        desde cualquier dispositivo y lugar.
                                    </p>
                                </div>
                                <v-avatar class="mr-3" rounded="0" size="48">
                                    <v-img src="../assets/ICONO13.png"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" sm="6" class="d-flex align-center mb-4">
                                <div class="text-right">
                                    <p class="badget-cloud ml-10">APLICACIONES</p>
                                    <p class="white--text">Softaculous, NodeJS, Python, Git y Ruby en Panel. Versiones de PHP hasta 7.4</p>
                                </div>
                                <v-avatar class="mr-3" rounded="0" size="48">
                                    <v-img src="../assets/ICONO15.png"></v-img>
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>

        <!-- Hosting Section -->
        <v-row>
            <v-container>
                <h2 class="text-center mb-6 custom-text-color">HOSTING</h2>
                <v-row>
                    <v-col cols="12" md="4" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <v-img src="../assets/aws.png" class="img-hosting"></v-img>
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                Tu información estará alojada en la nube de Amazon (AWS).
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/mailchannels.png" style="max-width: 130px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                Tu email es protegico con Mailchannels.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/litespeed.png" style="max-width: 245px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                Tu hosting y dominio rápido con Litespeed.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <p class="text-center spaced-paragraph" style="color: #676767;">Ofrecemos a tus clientes una experiencia super rápida con una carga de <br> página hasta un 100% más rápida de lo convencional.</p>
                <v-row class="justify-center" no-gutters>
                    <v-col cols="12" md="auto" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/ICONO16.png" style="max-width: 245px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <span class="highlighted-text">HOSTING Y DOMINIO SIN VIRUS O MALWARE</span> <br> Adiós a los hackers
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="auto" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/ICONO17.png" style="max-width: 245px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <span class="highlighted-text">HOSTING Y DOMINIO CON COPIAS DE SEGURIDAD</span>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="auto" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/ICONO18.png" style="max-width: 245px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <span class="highlighted-text">HOSTING Y DOMINIO CON COPIAS DE RESPALDO</span>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="auto" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/ICONO19.png" style="max-width: 245px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <span class="highlighted-text">HOSTING WORDPRESS RÁPIDO CON LITESPEED</span> <br> ¡Aceleramos tu sitio con CND incluido!
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="auto" class="d-flex flex-column align-center text-center">
                        <v-card class="text-center" elevation="0" max-width="300">
                            <div class="image-container">
                                <img src="../assets/ICONO20.png" style="max-width: 245px;">
                            </div>
                            <v-card-subtitle class="subtitle-card">
                                <span class="highlighted-text">FIREWALL ANTIVIRUS Y ANTIMALWARE</span>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </v-main>

    <!-- Footer Section -->
    <v-footer class="footer-background p-4 m-0">
        <v-container class="text-center">
            <v-row>
                <v-col cols="12" sm="6" md="4" class="d-flex justify-center align-center mb-4 mb-sm-0">
                    <p class="text-center text--black font-weight-bold support">Address: 929 Sw, 122ND Av, Miami, FL 33184<br> Mail: support@cloudhostsolutionsllc.com<br> Phone: +1 305-773-8095</p>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="d-flex justify-center align-center mb-4 mb-sm-0">
                    <img src="../assets/logo2.png" alt="Cloud Server" class="img-fluid" style="max-width: 150px;">
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-center align-center">
                    <v-btn class="mt-4" style="background: black; color: #fff;" rounded @click="dialog = true">Contactar</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>

    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
        <v-card>
            <v-toolbar color="#514cf1" dark>Formulario de contacto</v-toolbar>
            <v-card-text class="pb-0">
                <div class="text-h2 p-0 pb-0">
                    <v-form>
                        <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            class="mt-5 pb-0"
                            >
                                <v-text-field
                                    v-model="name"
                                    label="Nombre"
                                    filled
                                    class=""
                                ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            class="mt-0 mt-md-5"
                            sm="6"
                            >
                            <v-text-field
                                v-model="lastname"
                                label="Apellido"
                                filled
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-text-field
                            v-model="email"
                            label="Correo"
                            filled
                        ></v-text-field>
                        <v-textarea
                            v-model="message"
                            label="Mensaje"
                            filled
                        ></v-textarea>
                    </v-form>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text @click="dialog = false, sendContactForm()">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-app>
</template>

<script>
import axios from 'axios';

export default {
    name: 'App',
    data() {
        return {
            dialog: false,
            name: '',
            lastname: '',
            email: '',
            message: ''
        };
    },

    methods: {
        sendContactForm() {
            const formData = {
                name: this.name,
                lastname: this.lastname,
                email: this.email,
                message: this.message
            };

            axios.post('/send_email.php', formData)
                .then(response => {
                    // Manejo de la respuesta del servidor
                    alert('Correo enviado exitosamente');
                    console.log(response);  // Se movió dentro del bloque .then
                    this.dialog = false;
                })
                .catch(error => {
                    // Manejo del error
                    console.error(error);
                    alert('Hubo un error al enviar el correo.');
                });
        }
    }
};
</script>

<style scoped>
@media (width <=600px) {
    .img-fluid {
        width: 122px !important;
    }

    .links {
        font-size: 10px;
    }
}

@media (width <=373px) {
    .img-fluid {
        width: 80px !important;
    }

    .img-service {
        max-width: 81% !important;
    }

    .img-hero {
        width: 298px !important;
    }

    .support {
        font-size: 14px !important;
    }
}

@media (width <=300px) {
    .img-fluid {
        width: 81px !important;
    }

    .links {
        font-size: 8px;
    }
}

.support {
    font-size: 16px;
}

.img-hero {
    width: 380px;
}

.img-service {
    max-width: 100%;
    /* Aumenta el tamaño de la imagen */
    margin-right: -4%;
    /* Mueve la imagen hacia la derecha para que sobresalga */
}

.img-hosting {
    max-width: 100px;
    /* Ajusta el tamaño de las imágenes aquí */
    height: auto;
}

.image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.image-service-clo img {
    width: 100%;
    height: auto;
}

.custom-text-color {
    color: #514cf1;
}

.badget-desc {
    color: #4d4d4d;
    font-size: 26px;
}

.badget {
    color: white;
    border-radius: 14px;
    background-color: #514cf1;
    font-size: 14px;
}

.badget-cloud {
    color: #000a2b;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    text-align: right;
}

.border {
    border-color: #e5e5e5;
    border-style: solid;
    border-width: 2px;
    border-radius: 14px;
}

.hero-background {
    background-image: url('../assets/fondo.png');
}

.footer-background {
    background-image: url('../assets/footer.png');
}

.cloud-background {
    background-color: #000a2b;
    padding-top: 8px;
    /* Ajusta el valor según lo necesario */
    padding-bottom: 8px;
}

.img-fluid {
    width: 148px;
}

.img-icons {
    width: 100px;
}

.custom-hr {
    width: 60%;
    background-color: #c5c5c5;
}

.custom-title {
    color: #514cf1;
    font-size: 30px;
}

.text-left {
    text-align: left;
}

.spaced-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
}

.card-fixed-size {
    width: 280px;
    height: 260px;
}
</style><style>
.container {
    max-width: 1215px;
}

.red-border {
    box-shadow: 0 0 0 20px #514cf1;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

p {
    margin: 0;
    padding: 0;
}

.highlighted-text {
    font-weight: bold;
    text-transform: uppercase;
}
</style>
